<template>
    <b-card class="card-search">
        <b-table-simple hover small caption-top responsive>    
            <b-thead>      
                <b-tr>
                    <b-th>#</b-th>
                    <b-th>Champ</b-th>
                    <b-th>Ancienne valeur</b-th>
                    <b-th>Nouvelle valeur</b-th>
                    <b-th>Utilisateur</b-th>
                    <b-th>Date</b-th>        
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(l, i) in logs" :key="i">                    
                    <b-td>{{ i + 1 }}</b-td>
                    <b-td>{{ l.field }}</b-td>
                    <b-td>{{ l.old }}</b-td>
                    <b-td>{{ l.new }}</b-td>
                    <b-td>{{ l.personne }}</b-td>
                    <b-td>{{ $api.moment(l.date).format("DD/MM/YYYY HH:mm:ss") }}</b-td>
                </b-tr>                        
            </b-tbody>    
        </b-table-simple>
    </b-card>
</template>
<script>  
export default {
    name: "Log",
    props:['logs'],
    components: {},
    data(){      
        return {}
    },
    watch:{      
    },
    computed:{      
    },
    beforeMount() {    
    },
    mounted() {      
    },
    methods: {      
    }
  }
  </script>
  <style>  
  </style>
  