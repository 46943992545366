<template>
    <div>
        <b-button v-b-tooltip.hover title="Voir l'historique des actions" variant="light-warning" size="md" class="btn-icon" v-b-toggle.history_side>
            <i class="fas fa-history fa-size"></i>
        </b-button>
        <b-sidebar  v-model="isOpen" id="history_side" title="Voir l'historique" backdrop-variant="light-warning" backdrop>                        
            <div class="shadow px-4 mt-3">
                <b-list-group>
                    <b-list-group-item v-for="(l, i) in liste" :key="i" href="javascript:void(0)">
                        <b-row>
                            <b-col cols="12">
                                <div>
                                    <span class="">{{ l.titre }}</span>                                    
                                </div>                                
                                <i><small>{{ $api.moment(l.date_create * 1000).format("DD/MM/YYYY HH:mm:ss") }}</small></i>
                            </b-col>                            
                        </b-row>
                    </b-list-group-item>          
                </b-list-group>
            </div>
        </b-sidebar>
    </div>
</template>
<script> 
export default {
    name: "Activity",
    props:['client_id'],
    components: {        
    },
    beforeCreate() {
    },
    computed:{        
    },
    watch:{
        isOpen(v){
            if(v === true){
                this.init()
            }
        }
    },
    data() {
        return {
            isOpen: false,
            liste: [],            
        }
    },
    methods: {       
        init(){
            this.$api.ajax('/activity/liste?model=Client&model_id='+this.client_id, null, res => {
                if(res.status === true){
                    this.liste = res.data;                                       
                }
            })
        },        
    },
    mounted(){
        this.init()
    }
}
</script>
<style scoped>

</style>