<template>
  <b-col md="12">
    <b-form-group label="Commentaire interne:">
      <b-form-textarea v-model="valueLocal" placeholder="Commentaire" rows="6" disabled></b-form-textarea>
    </b-form-group>
    <b-form-group label="Ajouter un commentaire">
      <b-input-group>
        <b-form-input v-model="tmpCommentaire" placeholder="Ajouter un commentaire" @keyup.enter="addCommentaire()"></b-form-input>
        <b-input-group-append>
          <b-button variant="success" @click="addCommentaire()" class="btn-icon"><i class="fas fa-plus"></i></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-col>
</template>
<script>
export default {
  name: "Commentaire",
  props: ['value'],
  model: {
    prop: 'value',
    event: 'inputChange'
  },
  data(){
    return {
      tmpCommentaire: null
    }
  },
  computed: {
    valueLocal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('inputChange', value)
      }
    }
  },
  methods: {
    addCommentaire(){
      if(this.tmpCommentaire === null){
        return null;
      }
      this.valueLocal = this.tmpCommentaire+` | ${this.$api.user.prenom[0]}${this.$api.user.nom[0]} le ${this.$api.moment().format("DD/MM/YYYY H:mm:ss")}`+"\n"+this.valueLocal;
      this.tmpCommentaire = null;
      var name = this.$route.name;
      if(name.indexOf('Detail du prospect') > -1 || name.indexOf('Detail du devis') > -1){                
        this.$parent.save();
      }
    }
  },
  beforeMount(){
    if(this.valueLocal === null){
      this.valueLocal = "";
    }
  },
}
</script>
