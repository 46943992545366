<template>
  <b-col md="6">
    <b-form-group label="Code Postal">
      <b-form-input v-model="obj.cp" minlength="5" maxlength="5" required></b-form-input>
      <b-list-group class="search-result" v-if="result.length > 0">      
        <b-list-group-item href="javascript:void(0)" v-for="(r,i) in result" :key="i" @click="setResult(r)">{{ r.Code_postal }} - {{ r.Nom_commune }}</b-list-group-item>      
      </b-list-group>
    </b-form-group>
  </b-col>  
</template>
<script>
export default {
  props: {    
    obj:{
      type: Object,
      required: true
    }
  },
  components: {    
  },
  name: "CodePostal",
  watch:{
    'obj.cp'(val){
      if(val === null){
        return false;
      }
      if(val.length < 3){
        return false
      }
      this.search(val);
    }
  },
  data(){
    return {
      result: []
    }
  },
  methods: {
    search(val){
      this.$api.ajax('/commune/search?cp='+val, null, res => {
        if(res.status === true){
          this.result = res.data;          
        }
      })
    },
    setResult(r){
      this.obj.cp = r.Code_postal;
      this.obj.ville = r.Nom_commune;
      setTimeout(() => {
        this.result = [];
      },200)
    }
  } 
}
</script>
<style lang="scss" scoped>
.search-result {
  position: absolute;
  width: 88%;
  z-index: 1;
}
</style>
