<template>
    <div>
        <b-button v-b-tooltip.hover title="Ajouter un rdv dans l'agenda" variant="warning" size="md" class="btn-icon" @click="open">
            <i class="fas fa-calendar fa-size"></i>
        </b-button>
        <b-modal id="eventTmp" ref="eventTmp" hide-footer title="RDV" size="xl">
            <b-row>
                <b-col :md="liste.length > 0 ? 7 : 12">
                    <form ref="form_event" id="form_event">
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Titre">
                                    <Input type="text" v-model="eventTmp.data.titre" :required="true" placeholder="titre" />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Categorie">
                                    <Input type="select-model" v-model="eventTmp.data.categorie_id" :required="true"
                                        :options="$api.params.AgendaCategorie" :multiple="false" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12" class="mb-3 text-center">
                                <b-button v-for="(f, i) in fasts" :key="i" variant="outline-primary" @click="setFast(f.type)" class="ml-2 mt-2">{{ f.titre }}</b-button>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Date">
                                    <input type="date" class="form-control" v-model="eventTmp.data.date" :required="true" placeholder="date" :min="$api.moment().format('YYYY-MM-DD')"/>                            
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Heure">
                                    <input class="form-control" type="time" v-model="eventTmp.data.heure" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Telephone">
                                    <Telephone v-model="eventTmp.data.telephone" :edit="true" />
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group label="Commentaire">
                                    <Input type="textarea" v-model="eventTmp.data.description" :required="false" placeholder="Commentaire" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-button variant="outline-primary" block @click="$refs.eventTmp.hide()">Fermer</b-button>
                            </b-col>
                            <b-col offset-md="4" md="4">
                                <b-button variant="primary" block @click="createEvent">Sauvegarder</b-button>
                            </b-col>
                        </b-row>                
                        <div class="mt-5">
                            <b-card-text class="text-center h5">Autre RDV</b-card-text>
                            <div>
                                <b-button v-for="(r, i) in rdv" :key="i" :variant="$api.p('AgendaCategorie',r.data.categorie_id).color" class="ml-2 mb-1" @click="eventTmp = r">{{ $api.moment(r.data.date).format("DD/MM/YYYY") }} à {{ r.data.heure }}</b-button>
                            </div>
                        </div>
                    </form>
                </b-col>
                <b-col md="5" v-if="liste.length > 0">
                    <b-list-group>
                        <b-list-group-item v-for="(l,i) in liste" :key="i" :href="l.data.url" target="_blank">{{ l.data.titre }} {{ $api.moment(l.data.date).format('DD/MM/YYYY') }} <strong>{{ l.data.heure }}</strong></b-list-group-item>                        
                    </b-list-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import Input from '@/components/InputForm';
import Telephone from '@/components/Telephone';
export default {
    name: "AgendaEvent",
    props: {    
        model:{
            type: String,
            required: true
        },
        model_id:{
            type: Number,
            required: true
        },
        rdv:{
            type: Array,
            required: true
        }
    },
    components: {
        Input, Telephone
    },
    watch:{
        'eventTmp.data.date'(v){
            var now = this.$api.moment( this.$api.moment().format("YYYY-MM-DD"));
            var date = this.$api.moment(v);
            if(v === null || now > date){
                this.liste = [];
                return false;
            }            
            var params = {
                "min_data.time": date.startOf('day').unix(),
                "max_data.time": date.endOf('day').unix(),                
            }
            this.$api.ajax('/agenda/liste', {filters:params}, res => {
                this.liste = res.data;
            })
        }
    },
    beforeCreate() { 
    },
    data() {
        return {
            eventTmp: {
                "id": null,
                "model": this.model ?? null,
                "model_id": this.model_id ?? null,
                "data": {
                    "titre": null,
                    "date": null,
                    "heure": null,
                    "description": null,
                    "categorie_id": null,
                    "url": null,
                }
            },
            fasts:[
                {
                    "titre":"Dans 1h",
                    "type":1
                },
                {
                    "titre":"Demain",
                    "type":2
                },
                {
                    "titre":"Demain meme heure",
                    "type":3
                },
                {
                    "titre":"Dans 2j.",
                    "type":4
                },
                {
                    "titre":"Dans 3j.",
                    "type":6
                },
                {
                    "titre":"Dans une semaine",
                    "type":5
                },
                {
                    "titre":"Dans 9 mois",
                    "type":7
                },
            ],
            liste:[]
        }
    },
    methods: {
        createEvent() {
            if(this.checkForm() === false){
                return false;
            }
            this.eventTmp.data.url = location.href;
            this.$api.ajax('/agenda/create', this.eventTmp, res => {
                if (res.status === true) {
                    this.$refs.eventTmp.hide();
                    this.eventTmp = {
                        "id": null,
                        "model": this.model ?? null,
                        "model_id": this.model_id ?? null,
                        "data": {
                            "titre": null,
                            "date": null,
                            "heure": null,
                            "description": null,
                            "categorie_id": null,
                            "url": null,
                        }
                    };
                }
            })
        },
        open() {
            var name = this.$route.name;
            if(name.indexOf('prospect') > -1){                
                this.eventTmp.data.titre = `#${this.$parent.client.id} ${this.$parent.client.nom ?? ""} ${this.$parent.client.prenom ?? ""}`
                this.eventTmp.data.categorie_id = 1;
                this.eventTmp.data.telephone = this.$parent.client.telephone_1;

            }else if(name.indexOf('devis') > -1){
                this.eventTmp.data.titre = `#${this.$parent.projet.id} ${this.$parent.projet.nom ?? ""} ${this.$parent.projet.prenom ?? ""}`
                this.eventTmp.data.categorie_id = 2;
                this.eventTmp.data.telephone = this.$parent.projet.telephone_1;
            }    
            this.$refs.eventTmp.show();
        },
        checkForm() {
            var res = true;
            if (this.$refs.form_event.reportValidity() === false) {
                res = false;
            }
            return res;
        },
        setFast(type){
            var tmp = this.$api.moment();
            switch (type) {
                case 1:                                        
                    tmp.add(1, 'hours');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;                
                case 2:
                    tmp.add(1, 'days');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = "09:30";
                break;
                case 3:
                    tmp.add(1, 'days');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;
                case 4:
                    tmp.add(2, 'days');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;
                case 5:
                    tmp.add(7, 'days');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;
                case 6:
                    tmp.add(3, 'days');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;
                case 7:
                    tmp.add(9, 'month');
                    this.eventTmp.data.date = tmp.format('YYYY-MM-DD');
                    this.eventTmp.data.heure = tmp.format('HH:mm');
                break;
            }
        }
    }
}
</script>